<style lang="scss">
    @import './scss/index.scss';
</style>

<template>
  <div v-if="currentMedia != null" v-bind:class="containerClasses">
    <SkinContainer />
    <DFPContainer />
    <PlayerManager />
    <OnAirInfoBox />
    <PlayerBar />
    <!-- <CloseButton /> -->
  </div>
</template>

<script>
import PlayerManager from './components/PlayerManager/PlayerManager'
import PlayerBar from './components/PlayerBar/PlayerBar'
import OnAirInfoBox from './components/OnAirInfoBox/OnAirInfoBox'
// import CloseButton from './components/CloseButton/CloseButton'
import SkinContainer from './components/SkinContainer/SkinContainer'
import DFPContainer from './components/DFPContainer/DFPContainer'
import store from './store'
import NotificationManager from './lib/notificationManager'
import { TOGGLE_FULLSCREEN, TOGGLE_LAYOUT_FULLSCREEN, UPDATE_CURRENT_MEDIA, UPDATE_RELATED_MEDIA, TOGGLE_SHOW, RESET_TO_DEFAULT, UPDATE_MEMBER_INFO, DELETE_MEMBER_INFO/*, UPDATE_NIELSEN_VIDEO_APP_ID*/ } from './store/mutation-types'
import { MEDIA_TYPE } from './lib/constants'
import { doAuthentication } from './lib/utility'

export default {
  store,
  name: 'MediaPlayer',
  data: () => ({
    showClass: false
  }),
  created () {
    doAuthentication()
    NotificationManager.initializePermissions()
    this.onFullscreenChange.bind(this)
    this.onOrientationChange.bind(this)
  },
  mounted () {
    document.addEventListener('fullscreenchange', this.onFullscreenChange)
    window.addEventListener('orientationchange', this.onOrientationChange)
  },
  components: {
    PlayerManager,
    OnAirInfoBox,
    PlayerBar,
    // CloseButton,
    SkinContainer,
    DFPContainer
  },
  computed: {
    show () { return this.$store.state.layout.show },
    auth () { return this.$store.state.layout.auth },
    containerClasses () { return { 'mediaPlayer-container': true, show: this.showClass } },
    currentMedia () { return this.$store.state.mediaInfo.currentMedia },
    playoutInfo () { return this.$store.state.playoutInfo },
    isPlaying () { return this.playoutInfo.isPlaying },
    isFullscreen () { return this.playoutInfo.isFullscreen },
    isVideo () { return this.currentMedia !== null && this.currentMedia.type === MEDIA_TYPE.VIDEO }
  },
  watch: {
    show (newValue) {
      document.body.classList.toggle('media-player-opened', newValue)
      setTimeout(() => {
        this.showClass = newValue
      }, newValue ? 150 : 0)
      if (!newValue) {
        this.$store.dispatch(RESET_TO_DEFAULT)
      }
    }
  },
  methods: {
    onFullscreenChange () {
      this.$store.commit(TOGGLE_FULLSCREEN, document.fullscreenElement !== null)
    },
    onOrientationChange (event) {
      if (event.target.screen.orientation.type.indexOf('landscape') !== -1 && this.isPlaying && !this.isFullscreen && this.isVideo) {
        this.$store.commit(TOGGLE_LAYOUT_FULLSCREEN)
      }
    },
    setCurrentMedia ({ mediaInfo, relatedMediaInfo }) {
      this.$store.commit(UPDATE_CURRENT_MEDIA, mediaInfo)
      this.$store.commit(UPDATE_RELATED_MEDIA, relatedMediaInfo)
      this.$store.commit(TOGGLE_SHOW, true)
    },
    setUserToken (memberCommunityInfos) {      
      if (memberCommunityInfos !== null){
        const { access_token, device_info, device_platform, device_uid, refresh_token } = memberCommunityInfos
        this.$store.commit(UPDATE_MEMBER_INFO, { access_token, device_info, device_platform, device_uid, refresh_token })
      }
      else {
        this.$store.commit(DELETE_MEMBER_INFO, null)
      }
    }/*,
    setNielsenVideoAppIds (nielsenVideoAppIds) {
      this.$store.commit(UPDATE_NIELSEN_VIDEO_APP_ID, nielsenVideoAppIds)
    }*/
  },
  beforeDestroy: function () {
    document.removeEventListener('fullscreenchange', this.onFullscreenChange)
    document.addEventListener('orientationchange', this.onOrientationChange)
  }
}
</script>
