import { TOGGLE_LAYOUT_PLAY_PAUSE, TOGGLE_LAYOUT_FULLSCREEN, TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX, UPDATE_LAYOUT_CAN_SEND_NOTIFICATION, TOGGLE_PREROLL_IS_PLAYING, TOGGLE_SHOW, FIREBASE_AUTH_START, FIREBASE_AUTH_FAILED, FIREBASE_AUTH_SUCCESS, RESET_TO_DEFAULT } from '../mutation-types'

export const initialState = {
  show: false,
  togglePlayPause: false,
  toggleFullscreen: false,
  isPrerollPlaying: false,
  showOnAirInfoBox: false,
  canSendNotification: false,
  auth: false
}

const layoutModule = {
  state: () => ({ ...initialState }),
  mutations: {
    [FIREBASE_AUTH_START] (state) {
      state.auth = false
    },
    [FIREBASE_AUTH_SUCCESS] (state) {
      state.auth = true
    },
    [FIREBASE_AUTH_FAILED] (state) {
      state.auth = false
    },
    [TOGGLE_LAYOUT_PLAY_PAUSE] (state) {
      state.togglePlayPause = !state.togglePlayPause
    },
    [TOGGLE_LAYOUT_FULLSCREEN] (state) {
      state.toggleFullscreen = !state.toggleFullscreen
    },
    [TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX] (state, newState) {
      const newValue = (newState === undefined) ? !state.showOnAirInfoBox : newState
      state.showOnAirInfoBox = newValue
    },
    [UPDATE_LAYOUT_CAN_SEND_NOTIFICATION] (state, canSendNotification) {
      state.canSendNotification = canSendNotification
    },
    [TOGGLE_PREROLL_IS_PLAYING] (state, isPrerollPlaying) {
      state.isPrerollPlaying = isPrerollPlaying
    },
    [TOGGLE_SHOW] (state, newState) {
      state.show = newState
    }
  },
  actions: {
    [RESET_TO_DEFAULT] ({ commit }) {
      commit(TOGGLE_PREROLL_IS_PLAYING, false)
      commit(TOGGLE_LAYOUT_SHOW_ONAIR_INFO_BOX, false)
    }
  }
}

export default layoutModule
