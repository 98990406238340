<style lang="scss" scoped>
  @import './OnAirMessages.scss';
</style>
<template>
    <div class="onair-messages-container">
        <iframe v-bind:src="this.socialUrl"></iframe>
        <button v-if="!isLogged && showButton" v-bind:class="btnClass" v-on:click="doLoginEvt">Accedi per interagire in diretta</button>
    </div>
</template>
<script>
import { emitDoLoginEvent } from '../../lib/utility'
export default {
  name: 'OnAirMessages',

  data: () => {
    return {
      showButton: false,
      // showIframe: false,
      btnClass: 'btn'
    }
  },

  mounted: function () {
    /*if (this.isLogged){
      this.showIframe = true;
    }*/
    if (!this.isLogged){
      this.showButton = true;

      /*
      setTimeout(() => { 
        this.btnClass += " hide";
        this.showIframe = true;

        setTimeout(() => { 
          this.showButton = false;
        }, 3000);
      }, 10000)*/
    }    
  },
  computed: {
    currentMedia () {
      return this.$store.state.mediaInfo.currentMedia
    },
    socialUrl () {
      let baseUrl = this.currentMedia.socialUrl
      
      const { access_token = null, currentDate = 10 } = this.memberInfo
      if (access_token !== null) {
        baseUrl = baseUrl + "?_=" + currentDate + "&access_token=" + access_token
      }
      return baseUrl
    },
    memberInfo () {
      return this.$store.state.memberInfo;
    },
    isLogged () {
      const { access_token = null } = this.memberInfo
      return access_token !== null && access_token !== ""
    }
  },
  methods: {
    doLoginEvt: () => {
      emitDoLoginEvent();
    }
  }
}
</script>