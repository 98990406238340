import { UPDATE_MEMBER_INFO, DELETE_MEMBER_INFO } from '../mutation-types'

export const initialState = {
  access_token: null,
  device_info: null,
  device_platform: null,
  device_uid: null,
  refresh_token: null,
  currentDate: null
}

const memberInfoModule = {
  state: () => ({ ...initialState }),
  mutations: {
    [UPDATE_MEMBER_INFO] (state, { access_token, device_info, device_platform, device_uid, refresh_token }) {
        state.access_token = access_token
        state.device_info = device_info
        state.device_platform = device_platform
        state.device_uid = device_uid
        state.refresh_token = refresh_token
        state.currentDate = new Date().getTime()
    },
    [DELETE_MEMBER_INFO] (state) {
        state.access_token = null;
        state.device_info = null
        state.device_platform = null
        state.device_uid = null
        state.refresh_token = null
        state.currentDate = new Date().getTime()
    }
  }
}

export default memberInfoModule
