import Vue from 'vue'
import Vuex from 'vuex'
import advertising from './modules/advertising'
import playoutInfo from './modules/playoutInfo'
import layout from './modules/layout'
import mediaInfo from './modules/mediaInfo'
import userInfo from './modules/userInfo'
import memberInfo from './modules/memberInfo'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    advertising,
    playoutInfo,
    layout,
    mediaInfo,
    userInfo,
    memberInfo
  }
})
