<style lang="scss" scoped>
  @import './PlayerManager.scss';
</style>
<template>
  <div v-bind:class="containerClass">
    <OnAirMessages v-if="showOnAirMessages" />
    <div class="player-wrapper">
      <YoutubePlayer v-bind:current-media="currentMedia" v-if="currentMedia.channel === MEDIA_CHANNEL.YOUTUBE" />
      <THEOPlayer v-bind:current-media="currentMedia" v-bind:adv-tag="advTag" v-if="isTHEOPlayer" />
    </div>
  </div>
</template>
<script>

import THEOPlayer from '../THEOPlayer/THEOPlayer'
import YoutubePlayer from '../YoutubePlayer/YoutubePlayer'
import OnAirMessages from '../OnAirMessages/OnAirMessages';
import { MEDIA_CHANNEL, MEDIA_SOURCE, MEDIA_TYPE } from '../../lib/constants'
import { toggleFullScreen } from '../../lib/utility'

export default {
  name: 'PlayerManager',

  created () {
    this.MEDIA_CHANNEL = MEDIA_CHANNEL
    this.MEDIA_TYPE = MEDIA_TYPE
  },

  components: {
    YoutubePlayer,
    THEOPlayer,
    OnAirMessages
  },
  computed: {
    fullscreen () { return this.$store.state.layout.toggleFullscreen },
    isFullscreen () { return this.$store.state.playoutInfo.isFullscreen },
    containerClass () {
      return {
        'player-manager': true,
        'preroll-is-playing': this.$store.state.layout.isPrerollPlaying,
        video: this.currentMedia.type === MEDIA_TYPE.VIDEO,
        audio: this.currentMedia.type === MEDIA_TYPE.AUDIO,
        'on-demand': this.currentMedia.source === MEDIA_SOURCE.ON_DEMAND,
        live: this.currentMedia.source === MEDIA_SOURCE.LIVE
      }
    },
    isPrerollPlaying() {
      return this.$store.state.layout.isPrerollPlaying
    },
    currentMedia () {
      return this.$store.state.mediaInfo.currentMedia
    },
    hasMessageOverlay () {
      return this.currentMedia.socialUrl && this.currentMedia.socialUrl !== ""
    },
    advTag () {
      return this.$store.state.mediaInfo.advTag
    },
    nielsenVideoAppIds () {
      return this.$store.state.mediaInfo.nielsenVideoAppIds
    },
    isTHEOPlayer () {
      return this.currentMedia.channel === MEDIA_CHANNEL.WEB || this.currentMedia.channel === MEDIA_CHANNEL.MAINSTREAMING
    },
    showOnAirMessages () {
      return this.currentMedia.type === MEDIA_TYPE.VIDEO && this.hasMessageOverlay && !this.isPrerollPlaying
    }
  },
  watch: {
    fullscreen (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.toggleFullscreen(newValue)
      }
    },
  },
  methods: {
    toggleFullscreen () {
      const { type } = this.currentMedia
      if (type === MEDIA_TYPE.AUDIO) {
        toggleFullScreen(document.body);
        /*if (!this.isFullscreen) {
          enterFullscreenMode(document.body)
        } else {
          exitFullscreenMode(document.body)
        }*/
      } else {
        toggleFullScreen(document.querySelector('.player-manager'))
        /*
        const isFullscreen = this.player.ui.isFullscreen()
        if (isFullscreen) {
          this.player.ui.exitFullscreen()
        } else {
          this.player.ui.requestFullscreen()
        }*/
      }
    }
  }
}
</script>
